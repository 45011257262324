import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Form, Input, Modal, Alert } from 'antd';
import EdButton from '../../Button';
import EdbInput from '../../../components/Input';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  requiredMessage,
  style,
  ssnFormList,
  patternMessage,
  dataInfoReapetError,
  publicFormList,
  personStatusTrue,
  personDateFalse,
  notSubbmitingData,
  searchErrorFalse,
  NUMBERS_USER,
  personDateFalseChildrenCase,
  COLORS,
} from '../../../helpers/userapp';
import EdbMaskedInput from '../../MaskedInput';
import useSsnVerificationPublic from '../../../api/User/useSsnVerificationPublic';
import EdbDatePicker from '../../DatePicker';
import moment from 'moment';
import GridModal from '../GridModal/GridModal';
import calculate_age from '../GeneralInfo/calculateAge';
import useCheckOtherApp from '../../../api/User/useCheckOtherApp';
import ArmenianInput from '../../../components/Input/ArmenianInput';

export const PersonSsn = (props) => {
  const form = Form.useFormInstance();
  const [isSsnError, getSsnError] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [openDateModalError, setOpenDateModalError] = useState(false);
  const [getPersonReapetError, setPersonReapetError] = useState(false);
  const firstInputRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const { mutate: mutatePersonCheck } = useCheckOtherApp({
    onSuccess: (res) => {
      if (res.data === true) {
        setPersonReapetError(false);
        form.setFieldsValue({
          is_success: true,
          is_success_true: true,
        });
      }
    },
    onError: (resError) => {
      setOpenModalError(true);
      setPersonReapetError(<p style={{ fontWeight: 'bold' }}>{resError.response.data.Message}</p>);

      form.setFieldsValue({
        is_success: true,
        is_success_true: false,
      });
    },
    enabled: false,
  });

  const { mutate: publicData } = useSsnVerificationPublic({
    onSuccess: (payload) => {
      if (payload.data === true) {
        if (form.getFieldValue('primary_assessment') === 2) {
          var pathParam = '/Edisability/CheckInitialApplicationExistence';
        } else {
          var pathParam = '/EDisability/CheckOtherApplicationsExistence';
        }

        mutatePersonCheck([
          {
            ssn: form.getFieldValue('Person_ssn'),
            subtypeId: form.getFieldValue('radio_button_id'),
          },
          pathParam,
        ]);
      } else {
        setOpenModalError(true);
        setPersonReapetError(false);
        form.setFieldsValue({
          is_success: false,
          is_success_true: false,
        });
      }
    },
    // onError: errorMessage,
  });

  const getParamsData = () => {
    // if(form.getFieldValue("Person_ssn") !== isPersonalInfoPerson) {
    form.setFieldsValue({
      Person_phoneNumber: null,
      Person_email: null,
    });

    form.resetFields(['Person_phoneNumber']);

    if (form.getFieldValue('Person_ssn') === form.getFieldValue('Representative_ssn')) {
      getSsnError(dataInfoReapetError);
    } else {
      getSsnError(false);
      setOpenModalError(false);
      if (
        calculate_age(form.getFieldValue('Person_birthDate')) < 16 &&
        form.getFieldValue('Representative_ssn') === null
      ) {
        setOpenDateModal(true);
        setOpenDateModalError(personDateFalse);
      } else {
        if (
          form.getFieldValue('select_item1') === NUMBERS_USER.ITEMCASEONE &&
          calculate_age(form.getFieldValue('Person_birthDate')) >= 16
        ) {
          setOpenDateModal(true);
          setOpenDateModalError(personDateFalseChildrenCase);
        } else if (
          form.getFieldValue('select_item1') === NUMBERS_USER.ITEMCASETWO &&
          calculate_age(form.getFieldValue('Person_birthDate')) < 16
        ) {
          setOpenDateModal(true);
          setOpenDateModalError(personDateFalse);
        } else {
          const jsonDataPublic = {
            ssn: form.getFieldValue('Person_ssn'),
            firstName: form.getFieldValue('Person_firstName'),
            lastName: form.getFieldValue('Person_lastName'),
            patronymicName: form.getFieldValue('Person_patronymicName'),
            birthDate: moment(form.getFieldValue('Person_birthDate')).format('DD/MM/YYYY'),
            isLegal:
              form.getFieldValue('radio_button_id') === 2 || form.getFieldValue('radio_button_id') === 10
                ? true
                : false,
          };
          publicData(jsonDataPublic);
        }
      }
    }
    // }
  };

  const hideDateModal = () => {
    setOpenDateModal(false);
    window.location.reload(false);
  };

  const hideDateModalError = () => {
    setOpenModalError(false);
    form.setFieldsValue({
      Person_firstName: null,
      Person_lastName: null,
      Person_patronymicName: null,
      Person_ssn: null,
      Person_birthDate: null,
      is_success: false,
    });
  };

  const emailNoticeChange = (e) => {
    if (e.target.value.length > 0) {
      form.setFieldsValue({
        email_check: 'true',
      });
    } else {
      if (form.getFieldValue('Representative_email') === null) {
        form.resetFields(['email_check']);
      } else {
        form.setFieldsValue({
          email_check: 'true',
        });
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && document.activeElement === buttonRef.current) {
      getParamsData();
    }
  };

  return (
    <>
      <Row style={{ gap: 10 }}>
        {publicFormList.data.map((publicSsnList, index) => (
          <Col key={publicSsnList.label} span={7} xs={22} sm={11} md={7}>
            <Form.Item
              {...formItemLayout}
              label={publicSsnList.label}
              name={`Person_${publicSsnList.name}`}
              rules={[{ required: publicSsnList.required, message: requiredMessage }]}
            >
              {publicSsnList.name === 'birthDate' ? (
                <EdbDatePicker
                  aria-label={publicSsnList.ariaLabel}
                  format="DD/MM/YYYY"
                  disabled={form.getFieldValue('is_success') === true ? 'disabled' : false}
                />
              ) : (
                ['firstName', 'lastName', 'patronymicName'].includes(publicSsnList.name) ? (
                  <ArmenianInput
                    aria-label={`${index === 0 ? props.title : ''} ${publicSsnList.ariaLabel}`}
                    ref={index === 0 ? firstInputRef : null}
                    disabled={form.getFieldValue('is_success') === true ? 'disabled' : false}
                    style={style}
                  />
                ) : (
                  <EdbInput
                    aria-label={`${index === 0 ? props.title : ''} ${publicSsnList.ariaLabel}`}
                    ref={index === 0 ? firstInputRef : null}
                    disabled={form.getFieldValue('is_success') === true ? 'disabled' : false}
                    style={style}
                  />
                )
              )}
            </Form.Item>

            <Form.Item label={false} name="is_success">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        ))}
        <Col
          span={7}
          sm={24}
          xs={12}
          md={7}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <EdButton
            aria-label="Նույնականացնել"
            onKeyDown={(e) => handleKeyDown(e)}
            ref={buttonRef}
            onClick={getParamsData}
          >
            Որոնել
          </EdButton>
        </Col>
        <Col span={15} style={{ marginBottom: '30px' }}>
          {getPersonReapetError != false ? (
            <GridModal
              title={
                <h3>
                  <CheckCircleOutlined
                    tooltip="Ծանուցում"
                    style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
                  />{' '}
                  Ծանուցում
                </h3>
              }
              open={openModalError}
              onOk={hideDateModalError}
              onCancel={hideDateModalError}
              footer={false}
            >
              {getPersonReapetError}
            </GridModal>
          ) : (
            ''
          )}
        </Col>
        <Col span={15} /*style={{marginBottom: '30px'}}*/>
          <Form.Item label={false} name="is_success_true" rules={[{ required: true, message: notSubbmitingData }]}>
            <Input type="hidden" />
          </Form.Item>
        </Col>
      </Row>

      {form.getFieldValue('is_success') === true ? (
        <Alert message={<span style={{ fontWeight: 'bold' }}>Անձը նույնականացվել է</span>} type="success" showIcon />
      ) : form.getFieldValue('is_success') === false ? (
        <GridModal
          title={
            <h3>
              <CheckCircleOutlined
                tooltip="Ծանուցում"
                style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
              />{' '}
              Ծանուցում
            </h3>
          }
          open={openModalError}
          onOk={hideDateModalError}
          onCancel={hideDateModalError}
          footer={false}
        >
          {searchErrorFalse}
        </GridModal>
      ) : (
        ''
      )}

      <Row style={{ marginBottom: '0' }}>
        <Col span={12}>{isSsnError}</Col>
      </Row>

      <Row style={{ gap: 10, borderTop: '1px solid #C0C1C2' }}>
        {ssnFormList.data.map((ssnList) => (
          <Col key={ssnList.label} span={7} xs={24} sm={11} md={7}>
            <Form.Item
              {...formItemLayout}
              label={ssnList.label}
              name={`Person_${ssnList.name}`}
              rules={[
                ssnList.name === 'email' ? { type: 'email', message: patternMessage } : '',
                ssnList.name === 'email' && props.checkEmail === true
                  ? { required: true, message: requiredMessage }
                  : ssnList.name === 'email' && props.checkEmail === false
                    ? { required: ssnList.required, message: requiredMessage }
                    : '',
                ssnList.name === 'phoneNumber' ? { pattern: /^[+]\d{2,}$/, message: patternMessage } : '',
                { required: ssnList.required, message: requiredMessage },
              ]}
            >
              {ssnList.name === 'phoneNumber' ? (
                <EdbMaskedInput disabled={ssnList.disabled} aria-label={ssnList.ariaLabel} size="large" style={style} />
              ) : ssnList.name === 'email' ? (
                <EdbInput
                  disabled={ssnList.disabled}
                  aria-label={ssnList.ariaLabel}
                  style={style}
                  onChange={emailNoticeChange}
                />
              ) : (
                <EdbInput disabled={ssnList.disabled} aria-label={ssnList.ariaLabel} style={style} />
              )}
            </Form.Item>
            {ssnList.name === 'email' ? (
              <p style={{ marginTop: '15px', color: COLORS.whiteGray }}>
                <ExclamationCircleOutlined /> Եթե ցանկանում եք ծանուցումները եւ/կամ որոշումը ստանալ էլ. փոստով, ապա այս
                դաշտի լրացումը պարտադիր է
              </p>
            ) : (
              ''
            )}
          </Col>
        ))}
      </Row>

      <>
        <GridModal
          title={
            <h3>
              <CheckCircleOutlined
                tooltip="Ծանուցում"
                style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
              />{' '}
              Ծանուցում
            </h3>
          }
          open={openDateModal}
          onOk={hideDateModal}
          onCancel={hideDateModal}
          footer={false}
        >
          {openDateModalError}
        </GridModal>
      </>
    </>
  );
};

export default PersonSsn;
