// import { armenianLettersPattern } from 'helpers/constants';
import Input from '.';

const ArmenianInput = (props) => {
  const handleKeyPress = (event) => {
    const armenianRegex = /^[\u0561-\u0587\u0531-\u0556\s-]+$/;
    const key = event.key;
    const valueWithKey = event.target.value + key;
    if (!armenianRegex.test(valueWithKey)) {
      event.preventDefault();
    }
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.toUpperCase();
  };

  return <Input onKeyPress={handleKeyPress} {...props} onInput={handleInput} />;
};

export default ArmenianInput;