import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Form, Input } from 'antd';
import EdButton from '../../Button';
import EdbInput from '../../../components/Input';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  requiredMessage,
  style,
  ssnFormList,
  patternMessage,
  dataReassessmentError,
  dataInfoReapetError,
  personStatusTrue,
  publicFormList,
  personDateFalse,
  notSubbmitingData,
  searchErrorFalse,
  NUMBERS_USER,
  personDateFalseChildrenCase,
} from '../../../helpers/userapp';
import EdbMaskedInput from '../../MaskedInput';
import useGetDecisionNumber from '../../../api/Application/useGetDecisionNumber';
import useSsnVerificationPublic from '../../../api/User/useSsnVerificationPublic';
import EdbDatePicker from '../../DatePicker';
import GridModal from '../GridModal/GridModal';
import moment from 'moment';
import calculate_age from '../GeneralInfo/calculateAge';
import useDoesHaveInitialApp from '../../../api/Application/useDoesHaveInitialApp';
import ArmenianInput from '../../../components/Input/ArmenianInput';

export const PersonSsnReassessment = (props) => {
  const { checkHasDecision = true } = props;

  const form = Form.useFormInstance();
  //! pay attention to variable nameings & logic (https://react.dev/reference/react/useState)
  //! use code formatter
  const [isPersonalInfoPerson, getPersonalInfoPerson] = useState();
  const [isSsnError, getSsnError] = useState(false);
  //! name state variables like [something, setSomething] & read documentation
  const [isDecisionError, getDecisionError] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [openDateModalError, setOpenDateModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const firstInputRef = useRef(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const getParamsData = () => {
    form.setFieldsValue({
      Person_phoneNumber: null,
      Person_email: null,
      decisionId: null,
    });

    form.resetFields(['Person_phoneNumber']);
    getDecisionError(false);

    if (form.getFieldValue('Person_ssn') === form.getFieldValue('Representative_ssn')) {
      getSsnError(dataInfoReapetError);
    } else {
      getSsnError(false);
      setOpenModalError(false);
      if (
        calculate_age(form.getFieldValue('Person_birthDate')) < 16 &&
        form.getFieldValue('Representative_ssn') === null
      ) {
        setOpenDateModal(true);
        setOpenDateModalError(personDateFalse);
      } else {
        if (
          form.getFieldValue('select_item1') === NUMBERS_USER.ITEMCASEONE &&
          calculate_age(form.getFieldValue('Person_birthDate')) >= 16
        ) {
          setOpenDateModal(true);
          setOpenDateModalError(personDateFalseChildrenCase);
        } else if (
          form.getFieldValue('select_item1') === NUMBERS_USER.ITEMCASETWO &&
          calculate_age(form.getFieldValue('Person_birthDate')) < 16
        ) {
          setOpenDateModal(true);
          setOpenDateModalError(personDateFalse);
        } else {
          const jsonDataPublic = {
            ssn: form.getFieldValue('Person_ssn'),
            firstName: form.getFieldValue('Person_firstName'),
            lastName: form.getFieldValue('Person_lastName'),
            patronymicName: form.getFieldValue('Person_patronymicName'),
            birthDate: moment(form.getFieldValue('Person_birthDate')).format('DD/MM/YYYY'),
            isLegal: true,
          };
          publicData(jsonDataPublic);
          //! Inappropriate way to update mutation function condition
          // getPersonalInfoPerson(form.getFieldValue('Person_ssn'));
        }
      }
    }
  };

  const { mutate: publicData } = useSsnVerificationPublic({
    onSuccess: (payload) => {
      if (payload.data === true) {
        getPersonalInfoPerson(form.getFieldValue('Person_ssn'));
        form.setFieldsValue({
          is_success: true,
          is_success_true: true,
        });
      } else {
        setOpenModalError(true);
        form.setFieldsValue({
          is_success: false,
          is_success_true: false,
        });
      }
    },
    // onError: errorMessage,
  });

  useGetDecisionNumber(
    {
      SSN: isPersonalInfoPerson,
    },
    {
      onSuccess: (res) => {
        if (res) {
          if (res.isAllowed === true) {
            props.desicionInfo(res);
            form.setFieldsValue({
              decisionId: res.decisionId,
              decisionNumber: res.decisionNumber,
            });
          } else if (res.isAllowed === false) {
            getDecisionError(dataReassessmentError);
          }
        } else {
          getDecisionError(dataReassessmentError);
        }
      },
      onError: () => {
        getDecisionError(dataReassessmentError);
      },
      enabled: !!(isPersonalInfoPerson && checkHasDecision),
    }
  );

  useDoesHaveInitialApp(
    {
      ssn: isPersonalInfoPerson,
      subtypeId: props.subtypeId,
    },
    {
      onError: (error) => {
        setOpenModalError(true);
        setErrorMessage(error.response.data.Message);
        form.setFieldsValue({
          is_success: false,
          is_success_true: false,
        });
        getPersonalInfoPerson(null);
      },
      retry: false,
      enabled: !!(isPersonalInfoPerson && !checkHasDecision),
    }
  );

  const hideDateModal = () => {
    setOpenDateModal(false);
    setErrorMessage('');
    window.location.reload(false);
  };

  const hideDateModalError = () => {
    setErrorMessage('');
    setOpenModalError(false);
    form.setFieldsValue({
      Person_firstName: null,
      Person_lastName: null,
      Person_patronymicName: null,
      Person_ssn: null,
      Person_birthDate: null,
    });
  };

  return (
    <>
      <Row gutter={5}>
        {publicFormList.data.map((publicSsnList, index) => (
          <Col key={publicSsnList.label} span={`${index === 3 ? 16 : 8}`}>
            <Form.Item
              {...formItemLayout}
              label={publicSsnList.label}
              name={`Person_${publicSsnList.name}`}
              rules={[{ required: publicSsnList.required, message: requiredMessage }]}
            >
              {publicSsnList.name === 'birthDate' ? (
                <EdbDatePicker
                  format="DD/MM/YYYY"
                  disabled={form.getFieldValue('is_success') === true ? 'disabled' : false}
                  aria-label={publicSsnList.ariaLabel}
                />
              ) : (
                ['firstName', 'lastName', 'patronymicName'].includes(publicSsnList.name) ? (
                  <ArmenianInput
                    ref={publicSsnList.name === 'firstName' ? firstInputRef : null}
                    aria-label={`${index === 0 ? props.title : ''} ${publicSsnList.ariaLabel}`}
                    disabled={form.getFieldValue('is_success') === true ? 'disabled' : false}
                    style={style}
                  />
                ) : (
                  <EdbInput
                    ref={publicSsnList.name === 'firstName' ? firstInputRef : null}
                    aria-label={`${index === 0 ? props.title : ''} ${publicSsnList.ariaLabel}`}
                    disabled={form.getFieldValue('is_success') === true ? 'disabled' : false}
                    style={style}
                  />
                )
              )}
            </Form.Item>
            <Form.Item label={false} name="is_success">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        ))}
      </Row>

      <EdButton onClick={getParamsData} aria-label="Նույնականացնել" style={{ margin: '0 auto' }}>
        Որոնել
      </EdButton>

      <Col span={15}>
        <Form.Item label={false} name="is_success_true" rules={[{ required: true, message: notSubbmitingData }]}>
          <Input type="hidden" />
        </Form.Item>
      </Col>

      {form.getFieldValue('is_success') === true ? (
        personStatusTrue
      ) : form.getFieldValue('is_success') === false ? (
        <GridModal
          title={
            <h3>
              <CheckCircleOutlined
                tooltip="Ծանուցում"
                style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
              />{' '}
              Ծանուցում
            </h3>
          }
          open={openModalError}
          onOk={hideDateModalError}
          onCancel={hideDateModalError}
          footer={false}
        >
          {errorMessage || searchErrorFalse}
        </GridModal>
      ) : (
        ''
      )}

      <Row style={{ margin: '20px 0 30px 0' }}>
        <Col span={20}>{isSsnError}</Col>
        <Col span={20}>{isDecisionError}</Col>
      </Row>

      <Row gutter={5}>
        {ssnFormList.data.map((ssnList) => (
          <Col key={ssnList.label} span={12}>
            <Form.Item
              {...formItemLayout}
              label={ssnList.label}
              name={`Person_${ssnList.name}`}
              rules={[
                ssnList.name === 'phoneNumber' ? { pattern: /^[+]\d{2,}$/, message: patternMessage } : '',
                ssnList.name === 'email' ? { type: 'email', message: patternMessage } : '',
                ssnList.name === 'email' && props.checkEmail === true
                  ? { required: true, message: requiredMessage }
                  : ssnList.name === 'email' && props.checkEmail === false
                  ? { required: ssnList.required, message: requiredMessage }
                  : '',
                { required: ssnList.required, message: requiredMessage },
              ]}
            >
              {ssnList.name === 'phoneNumber' ? (
                <EdbMaskedInput disabled={ssnList.disabled} size="large" style={style} />
              ) : (
                <EdbInput disabled={ssnList.disabled} style={style} />
              )}
            </Form.Item>
          </Col>
        ))}
      </Row>

      <>
        <GridModal
          title={
            <h3>
              <CheckCircleOutlined
                tooltip="Ծանուցում"
                style={{ color: '#0B847F', fontSize: '25px', marginRight: '7px' }}
              />{' '}
              Ծանուցում
            </h3>
          }
          open={openDateModal}
          onOk={hideDateModal}
          onCancel={hideDateModal}
          footer={false}
        >
          {openDateModalError}
        </GridModal>
      </>
    </>
  );
};

export default PersonSsnReassessment;
